import { captureRemixErrorBoundaryError, withSentry } from "@sentry/remix";
import type { LinksFunction, MetaFunction } from "@remix-run/cloudflare";

import {
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  isRouteErrorResponse,
  useRouteError,
} from "@remix-run/react";
import RadixProvider from "./lib/rdt/radixProvider";
import Header from "./components/header";
import TransactionNotification from "./components/transaction-notification";

import "./styles/app.css";
// import { appsignal } from "./lib/appsignal";

export function links() {
  return [
    {
      rel: "shortcut icon",
      type: "image/x-icon",
      href: "https://hug.meme/favicon.ico",
    },
    {
      rel: "mask-icon",
      sizes: "any",
      href: "https://hug.meme/hug.png",
      color: "#5D0FC0",
    },
  ];
}

export const meta: MetaFunction = () => {
  return [{ title: "HUG Meme" }];
};

function App() {
  return <Outlet />;
}

export default withSentry(App);

export function ErrorBoundary() {
  const error = useRouteError();

  // if (error instanceof Error) {
  //    appsignal.sendError(error, (span) => {
  //       span.setAction('CatchAll');
  //    });
  // }

  // when true, this is what used to go to `CatchBoundary`
  if (isRouteErrorResponse(error)) {
    return (
      <html lang="en" className="bg-fixed bg-top bg-cover bg-no-repeat bg-sun">
        <head>
          <link
            href="https://fonts.googleapis.com/css2?family=Bangers&display=swap"
            rel="stylesheet"
          />
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link
            rel="preconnect"
            href="https://fonts.gstatic.com"
            crossOrigin=""
          />
          <link
            href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
            rel="stylesheet"
          ></link>
          <Meta />
          <Links />
        </head>
        <body>
          <Header />
          <main className="mt-5 md:mt-12 my-2">
            <div className="md:w-4/5 lg:w-3/4 xl:w-1/2 2xl:w-2/5 mx-4 mb-8 md:mx-auto">
              <h1 className="text-[60px] bangers text-center font-bold text-left p-8 text-white">
                Oops an error appeared
              </h1>
              <p className="text-white text-center">Status: {error.status}</p>
              <p>{error.data.message}</p>
            </div>
          </main>
        </body>
      </html>
    );
  }

  // Don't forget to typecheck with your own logic.
  // Any value can be thrown, not just errors!
  let errorMessage = "Unknown error";
  captureRemixErrorBoundaryError(error);
  // if (isDefinitelyAnError(error)) {
  //    errorMessage = error.message;
  // }

  return (
    <html lang="en" className="bg-fixed bg-top bg-cover bg-no-repeat bg-sun">
      <head>
        <Meta />
        <Links />
        <link
          href="https://fonts.googleapis.com/css2?family=Bangers&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Bangers&display=swap"
          rel="stylesheet"
        />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin=""
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link
          href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
          rel="stylesheet"
        ></link>
      </head>
      <body>
        <Header />
        <main className="mt-5 md:mt-12 my-2">
          <div className="md:w-4/5 lg:w-3/4 xl:w-1/2 2xl:w-2/5 mx-4 mb-8 md:mx-auto">
            <h1 className="text-[60px] bangers text-center font-bold bangers text-left p-8 text-white">
              An unexpected error occured.
            </h1>
            <p className="bangers text-center text-white">
              It's me, hi, I'm the problem, it's me:
            </p>
            <pre className="text-center">{errorMessage}</pre>
          </div>
        </main>
      </body>
    </html>
  );
}
